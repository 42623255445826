import React from 'react'
import Landing from './pages/landing/Landing';
import { Helmet } from 'react-helmet';
import favicon from './assets/imgs/favicon.ico';
import ph from './assets/imgs/ph.png'

function App() {
  return (
    <div className="wrapper">
      <Helmet>
        <link rel="icon" type="image/x-icon" href={favicon} />
        <link rel="shortcut icon" type="image/x-icon" href={favicon} />
        <link rel="apple-touch-icon" sizes="180x180" href={ph} />
        <link rel="manifest" href="./manifest.json" />
      </Helmet>
      <Landing />
    </div>
  );
}

export default App;
